import React from "react"

export default class InitializeCasAuth extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  componentDidMount() {
    this.form.current.submit()
  }

  render() {
    const { csrfToken, rfusk, trf, cad, grgt, glgn } = this.props

    return (
      <form ref={this.form} action="/auth/cas_auth" method="post">
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <input type="hidden" name="rfusk" id="rfusk" value={rfusk} />
        <input type="hidden" name="cad" id="cad" value={cad} />
        {trf && <input type="hidden" name="trf" id="trf" value={trf} />}
        {grgt && <input type="hidden" name="grgt" id="grgt" value={grgt} />}
        {glgn && <input type="hidden" name="glgn" id="glgn" value={glgn} />}
      </form>
    )
  }
}
